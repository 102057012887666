var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      !_vm.prjConfig.Anonymous
        ? _c(
            "div",
            { staticClass: "text-right ma-6" },
            [
              _vm.showWorkerMenu && _vm.platformWorkerId
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "text-none",
                                        attrs: { text: "", color: "indigo" }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      " Worker ID: " +
                                        _vm._s(_vm.platformWorkerId) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        181193631
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              key: "logout",
                              on: {
                                click: function($event) {
                                  _vm.$refs.dialogLogout.shown = true
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Log out")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showWorkerMenu && !_vm.platformWorkerId
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "indigo",
                        href: "../private-prod-login?project=" + _vm.projectName
                      }
                    },
                    [_vm._v(" Log in ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex flex-column" },
        [
          _c(
            "v-row",
            [
              _vm.prjConfig.ShowTitle
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h3 my-3",
                      attrs: { cols: "12" }
                    },
                    [_vm._v(" " + _vm._s(_vm.prjConfig.Title) + " ")]
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _vm.prjConfig.InstructionBtn
                    ? _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              _vm.$refs.dialogInstruction.shown = true
                            }
                          }
                        },
                        [_vm._v("Show Instruction")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-overlay",
                {
                  attrs: {
                    color: "white",
                    opacity: 0.6,
                    absolute: "",
                    value: _vm.loadingNextTemplate
                  }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "grey", indeterminate: "", size: "64" }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm.prjConfig.PageNavigation
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-8",
                          attrs: { cols: "12", height: "100" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-4 pa-2",
                                  attrs: {
                                    color: "white",
                                    disabled: !_vm.hasPrevTemplate
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.getTemplate("PREV")
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-4 pa-2",
                                  attrs: {
                                    color: "white",
                                    disabled: !_vm.hasNextTemplate
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.getTemplate("NEXT")
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "px-8", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-slide-x-reverse-transition",
                        { attrs: { "hide-on-leave": "" } },
                        [
                          _vm.showTemplate
                            ? _c(_vm.template, {
                                tag: "component",
                                attrs: {
                                  "nano-props": _vm.nanoProps,
                                  "prev-answer": _vm.prevAnswer
                                },
                                on: { submit: _vm.submit }
                              })
                            : _vm._e(),
                          !_vm.showTemplate && _vm.showPreviewTemplate
                            ? _c(
                                "div",
                                [
                                  _vm.prjConfig.Anonymous
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "indigo" },
                                          on: {
                                            click: function($event) {
                                              return _vm.anonymousLogin()
                                            }
                                          }
                                        },
                                        [_vm._v("Start Task")]
                                      )
                                    : _vm._e(),
                                  _c(_vm.previewTemplate, { tag: "component" })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("tutti-dialog", {
        ref: "dialogInstruction",
        attrs: {
          maxWidth: "1000",
          actions: [{ label: "Close", color: "green darken-1", text: true }]
        },
        scopedSlots: _vm._u([
          {
            key: "body-raw",
            fn: function() {
              return [
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", text: "" },
                        on: {
                          click: function($event) {
                            _vm.$refs.dialogInstruction.shown = false
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    )
                  ],
                  1
                ),
                _c(_vm.instructionTemplate, { tag: "component" })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-dialog", {
        ref: "dialogLogout",
        attrs: {
          title: "Are you sure to log out?",
          maxWidth: "500",
          actions: [
            {
              label: "Logout",
              color: "indigo darken-1",
              text: true,
              onclick: _vm.logout
            },
            { label: "Cancel", color: "grey darken-1", text: true }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(" Some of your working history may not be saved. ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-dialog", {
        ref: "dialogAdviseReturn",
        attrs: {
          maxWidth: "800",
          actions: [{ label: "OK", color: "grey darken-1", text: true }]
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "warning" } },
                  [_vm._v("mdi-alert")]
                ),
                _vm._v(" No more task is currently available ")
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(
                  " Thank you for your interest in this HIT! We are sorry but there is no more task available for you on this HIT for now."
                ),
                _c("br"),
                _vm._v(
                  " Please return this HIT (nothing else will happen while this page is open). "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-dialog", {
        ref: "dialogUnskippableNode",
        attrs: {
          maxWidth: "800",
          actions: [
            {
              label: "OK",
              color: "success",
              dark: true,
              onclick: _vm._onSubmitWorkSession
            }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "success" } },
                  [_vm._v("mdi-check-circle")]
                ),
                _vm._v(" You reached the end of this HIT ")
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(
                  " You reached the end of this task now; it might have been earlier than expected, but don't worry, you will still earn the same amount of reward."
                ),
                _c("br"),
                _vm._v(
                  " This HIT will be automatically submitted as you close this dialog. "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-dialog", {
        ref: "dialogSessionError",
        attrs: {
          maxWidth: "500",
          actions: [{ label: "OK", color: "grey darken-1", text: true }]
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "warning" } },
                  [_vm._v("mdi-alert")]
                ),
                _vm._v(" Please do one HIT at a time ")
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(
                  " Multiple concurrent sessions are not allowed for this HIT. Please finish the other HIT first."
                ),
                _c("br"),
                _vm._v(
                  " If you believe this is caused in error, please try again later or contact "
                ),
                _c(
                  "a",
                  { attrs: { href: "mailto:mturk04@pcl.cs.waseda.ac.jp" } },
                  [_vm._v("mturk04@pcl.cs.waseda.ac.jp")]
                ),
                _vm._v(". ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-dialog", {
        ref: "dialogCompleted",
        attrs: {
          maxWidth: "500",
          actions: [
            {
              label: "OK",
              color: "indigo darken-w",
              onclick: _vm._onSubmitWorkSession,
              dark: true
            }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "success" } },
                  [_vm._v("mdi-check-circle")]
                ),
                _vm._v(" Task Completed! ")
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(
                  " You finished all of our questions. Thank you for your contribution! "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }